<template>
  <van-tabs
    v-if="menus.length > 0"
    border
    color="#db6b81"
    title-active-color="#db6b81"
    v-model="menusActive"
  >
    <van-tab
      v-for="menu in menus"
      :key="menu.name"
      :title="menu.meta.title"
      :to="menu.path"
      replace
    >
      <van-cell v-if="subMenus.length > 0">
        <van-row type="flex" gutter="12">
          <van-col v-for="(subMenu, index) in subMenus" :key="subMenu.name">
            <van-tag
              color="#ebedf0"
              :text-color="(index === subMenusActive && '#db6b81') || '#646566'"
              round
              @click="() => subMenuClick(subMenu.path)"
            >
              {{ subMenu.meta.title }}
            </van-tag>
          </van-col>
        </van-row>
      </van-cell>
    </van-tab>
  </van-tabs>
</template>

<script>
import { Tabs, Tab, Tag, Row, Col, Cell } from "vant";

export default {
  name: "TopMenu",
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Tag.name]: Tag,
    [Row.name]: Row,
    [Col.name]: Col,
    [Cell.name]: Cell,
  },
  props: {
    menus: {
      type: Array,
      default: new Array(),
    },
  },
  data() {
    return {
      menusActive: 0,
      subMenusActive: 0,
    };
  },
  mounted() {
    this.menusActive = this.matchMenu(this.menus);
    this.subMenusActive = this.matchMenu(this.subMenus);
  },
  watch: {
    $route() {
      this.menusActive = this.matchMenu(this.menus);
      this.subMenusActive = this.matchMenu(this.subMenus);
    },
  },
  methods: {
    matchMenu(menus) {
      return menus.findIndex((menu) => {
        return this.$route.matched.find((m) => {
          return m.path == menu.path;
        });
      });
    },
    subMenuClick(path) {
      this.$router.replace(path);
    },
  },
  computed: {
    subMenus() {
      return this.menus.length > 0 &&
        this.menus[this.menusActive] &&
        this.menus[this.menusActive].children
        ? this.menus[this.menusActive].children
        : [];
    },
  },
};
</script>

<style lang="less" scoped>
.van-tag {
  padding: 4px 7px;
}
</style>
