<template>
  <div class="register-container">
    <van-form @submit="register">
      <van-tabs
        border
        color="#db6b81"
        title-active-color="#db6b81"
        line-width="80"
        animated
        swipeable
        v-model="formData.role"
      >
        <van-tab name="normal" title="非医生注册" title-style="font-size: 16px">
          <div style="height: 60px"></div>
          <van-cell-group :border="false">
            <van-field
              label="公司"
              placeholder="填写您所在的公司"
              clearable
              name="company"
              v-model="formData.company"
              :rules="[{ required: true }]"
            ></van-field>
            <van-field
              label="部门"
              placeholder="填写您所在的部门"
              clearable
              name="department"
              v-model="formData.department"
              :rules="[{ required: true }]"
            ></van-field>
            <van-field
              label="姓名"
              placeholder="填写您的姓名"
              clearable
              name="name"
              v-model="formData.name"
              :rules="[{ required: true }]"
            ></van-field>
            <van-field
              label="手机号"
              placeholder="填写您的手机号"
              clearable
              name="phoneNumber"
              v-model="formData.phoneNumber"
              :rules="[{ pattern: /^1[3456789]\d{9}$/ }]"
            ></van-field>
            <van-field
              label="验证码"
              placeholder="填写短信验证码"
              clearable
              name="captcha"
              v-model="formData.captcha"
              :rules="[{ required: true }]"
            >
              <template #button>
                <van-button
                  size="small"
                  type="primary"
                  color="#db6b81"
                  native-type="button"
                  :disabled="formData.phoneNumber == '' || captchaLoading"
                  @click="getCaptcha"
                  >{{
                    !captchaLoading ? "获取验证码" : captchaLoadingText
                  }}</van-button
                >
              </template>
            </van-field>
          </van-cell-group>
        </van-tab>
        <van-tab name="doctor" title="医生注册" title-style="font-size: 16px">
          <div style="height: 60px"></div>
          <van-cell-group :border="false">
            <van-field
              label="医院"
              placeholder="填写您所在的医院"
              clearable
              name="company"
              v-model="formData.company"
              :rules="[{ required: true }]"
            ></van-field>
            <van-field
              label="科室"
              placeholder="填写您所在的科室"
              clearable
              name="department"
              v-model="formData.department"
              :rules="[{ required: true }]"
            ></van-field>
            <van-field
              label="姓名"
              placeholder="填写您的姓名"
              clearable
              name="name"
              v-model="formData.name"
              :rules="[{ required: true }]"
            ></van-field>
            <van-field
              label="手机号"
              placeholder="填写您的手机号"
              clearable
              name="phoneNumber"
              v-model="formData.phoneNumber"
              :rules="[{ pattern: /^1[3456789]\d{9}$/ }]"
            ></van-field>
            <van-field
              label="验证码"
              placeholder="填写短信验证码"
              clearable
              name="captcha"
              v-model="formData.captcha"
              :rules="[{ required: true }]"
            >
              <template #button>
                <van-button
                  size="small"
                  type="primary"
                  color="#db6b81"
                  native-type="button"
                  :disabled="formData.phoneNumber == '' || captchaLoading"
                  @click="getCaptcha"
                  >{{
                    !captchaLoading ? "获取验证码" : captchaLoadingText
                  }}</van-button
                >
              </template>
            </van-field>
          </van-cell-group>
        </van-tab>
      </van-tabs>
      <div style="height: 32px"></div>
      <van-button
        type="primary"
        block
        round
        color="linear-gradient(to left top, #e2b2b8, #db6b81)"
        >注册</van-button
      >
    </van-form>
  </div>
</template>

<script>
import { Tabs, Tab, Field, CellGroup, Cell, Button, Form, Notify } from "vant";
import * as accountService from "@/api/account";

export default {
  name: "Register",
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Form.name]: Form,
  },
  data() {
    return {
      captchaLoading: false,
      captchaLoadingText: "",
      loading: false,
      formData: {
        role: "normal",
        company: "",
        department: "",
        name: "",
        phoneNumber: "",
        captcha: "",
      },
    };
  },
  methods: {
    getCaptcha() {
      var { phoneNumber } = this.formData;
      if (!/^1[3456789]\d{9}$/.test(phoneNumber)) {
        Notify({
          message: "手机号码格式不正确",
          background: "#db6b81",
        });
        return;
      }
      accountService
        .sendRegisterCaptcha({
          phoneNumber: phoneNumber,
        })
        .then(() => {
          var time = 30;
          this.captchaLoadingText = `获取验证码 (30)`;
          var timer = setInterval(() => {
            this.captchaLoadingText = `获取验证码 (${time--})`;
          }, 1000);
          setTimeout(() => {
            clearInterval(timer);
            this.captchaLoading = false;
          }, 30000);
          this.captchaLoading = true;
        })
        .catch((ex) => {
          this.captchaLoading = false;
          Notify({
            message: `${(ex.error && ex.error.message) || ex.message}`,
            background: "#db6b81",
          });
        });
    },
    register() {
      this.loading = true;
      accountService
        .register(this.formData)
        .then(() => {
          Notify({
            message: "注册成功",
            type: "success",
          });
          this.$router.replace({ name: "login" }); //重定向
          this.loading = false;
        })
        .catch((ex) => {
          this.loading = false;
          Notify({
            message: `注册失败，${
              (ex.error && ex.error.message) || ex.message
            }`,
            background: "#db6b81",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.register-container {
  margin-top: -1px;
  padding: 0 18px;

  .van-cell {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
</style>
