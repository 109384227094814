import Vue from "vue";
import VueRouter from "vue-router";
import { constantRouterMap } from "@/config/router.config";
// import BasicLayout from "../layouts/BasicLayout.vue";
// import Login from "../views/user/login.vue";
// import Register from "../views/user/register.vue";
// import Profile from "../views/user/profile.vue";
// import ArticleList from "../views/article/list.vue";

Vue.use(VueRouter);

// const routes = [
//   {
//     path: "/login",
//     component: Login,
//   },
//   {
//     path: "/register",
//     component: Register,
//   },
//   {
//     path: "/",
//     component: BasicLayout,
//     redirect: "/home",
//     children: [
//       {
//         name: "Home",
//         path: "/home",
//         component: ArticleList,
//       },
//       {
//         path: "/home1",
//         component: ArticleList,
//       },
//       {
//         path: "/home2",
//         component: ArticleList,
//       },
//       {
//         path: "/home3",
//         component: ArticleList,
//       },
//       {
//         name: "Profile",
//         path: "/profile",
//         component: Profile,
//       },
//     ],
//   },
// ];

const router = new VueRouter({
  routes: constantRouterMap,
});

export default router;
