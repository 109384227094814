<template>
  <div class="login-container">
    <van-form @submit="login">
      <van-cell-group :border="false">
        <van-cell :border="false">
          <h2>手机号快捷登录</h2>
        </van-cell>
        <van-field
          placeholder="填写手机号"
          right-icon="warning-o"
          clearable
          name="phone"
          v-model="formData.phone"
          :rules="[{ pattern: /^1[3456789]\d{9}$/ }]"
        ></van-field>
        <van-field
          placeholder="填写验证码"
          clearable
          name="captcha"
          v-model="formData.captcha"
          :rules="[{ required: true }]"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              :disabled="formData.phone == '' || captchaLoading"
              color="#db6b81"
              native-type="button"
              @click="getCaptcha"
              :loading-text="captchaLoadingText"
              >{{
                !captchaLoading ? "获取验证码" : captchaLoadingText
              }}</van-button
            >
          </template>
        </van-field>
        <van-cell value-class="tips">
          <router-link to="/user/register"
            >还没有账号？点击这里注册</router-link
          >
        </van-cell>
      </van-cell-group>
      <div style="height: 12px"></div>
      <van-button
        type="primary"
        block
        round
        native-type="submit"
        color="linear-gradient(to left top, #e2b2b8, #db6b81)"
        :loading="loading"
        >登录</van-button
      >
    </van-form>
  </div>
</template>

<script>
import {
  CellGroup,
  Cell,
  Form,
  Button,
  Field,
  Card,
  Checkbox,
  Notify,
} from "vant";
import * as accountService from "@/api/account";

export default {
  name: "Login",
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      captchaLoading: false,
      captchaLoadingText: "",
      loading: false,
      formData: {
        phone: "",
        captcha: "",
      },
    };
  },
  methods: {
    getCaptcha() {
      var { phone } = this.formData;
      if (!/^1[3456789]\d{9}$/.test(phone)) {
        Notify({
          message: "手机号码格式不正确",
          background: "#db6b81",
        });
        return;
      }
      accountService
        .sendLoginCaptcha({
          phoneNumber: phone,
        })
        .then(() => {
          var time = 30;
          this.captchaLoadingText = `获取验证码 (30)`;
          var timer = setInterval(() => {
            this.captchaLoadingText = `获取验证码 (${time--})`;
          }, 1000);
          setTimeout(() => {
            clearInterval(timer);
            this.captchaLoading = false;
          }, 30000);
          this.captchaLoading = true;
        })
        .catch((ex) => {
          this.captchaLoading = false;
          Notify({
            message: `${(ex.error && ex.error.message) || ex.message}`,
            background: "#db6b81",
          });
        });
    },
    login(data) {
      this.loading = true;
      this.$store
        .dispatch("Login", data)
        .then(() => {
          const redirect = this.$route.query.redirect
            ? decodeURIComponent(this.$route.query.redirect)
            : "/";
          this.$router.replace(redirect); //重定向
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          Notify({
            message: "登录失败，请检查验证码",
            background: "#db6b81",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login-container {
  padding: 18px;
  margin-top: 18px;

  .van-cell {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
.tips {
  text-align: left;

  a {
    color: #1989fa;
  }
}
</style>
