import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/connect/token",
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: `grant_type=captcha_credentials&client_id=${process.env.VUE_APP_AUTH_CLIENT_ID}&scope=${process.env.VUE_APP_AUTH_SCOPE}&phone=${data.phone}&captcha=${data.captcha}`,
  });
}

export function getInfo() {
  return request({
    url: "/api/abp/application-configuration",
    method: "get",
  });
}

export function sendLoginCaptcha(params) {
  return request({
    url: "/api/academy/account/send-login-captcha",
    method: "post",
    params,
  });
}

export function sendRegisterCaptcha(params) {
  return request({
    url: "/api/academy/account/send-register-captcha",
    method: "post",
    params,
  });
}

export function register(data) {
  return request({
    url: "/api/academy/account/register",
    method: "post",
    data,
  });
}
