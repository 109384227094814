<template>
  <div class="article-container" v-show="!loading">
    <p class="article-title" v-text="title"></p>
    <p class="article-date">英途学院 发布于 {{ publishedTime | day }}</p>
    <nut-video
      v-show="url != ''"
      :options="video.options"
      :sources="video.sources"
    ></nut-video>
    <p class="article-content" v-html="content"></p>
  </div>
</template>

<script>
import * as articleService from "@/api/article";
import { Skeleton } from "vant";
import Video from "@nutui/nutui/dist/packages/video/video.vue";
import "@nutui/nutui/dist/packages/video/video.css";

export default {
  name: "ArticleShow",
  components: {
    [Skeleton.name]: Skeleton,
    [Video.name]: Video,
  },
  data() {
    return {
      loading: false,
      title: "",
      content: "",
      url: "",
      poster: "",
      publishedTime: Date.now(),
    };
  },
  computed: {
    video() {
      return {
        sources: [
          {
            src: this.url,
            type: "video/mp4",
          },
        ],
        options: {
          controls: true,
          playsinline: true,
          poster: this.poster,
        },
      };
    },
  },
  mounted() {
    const id = this.$route.params.id;
    this.loading = true;
    articleService.getArticle(id).then((res) => {
      this.title = res.title;
      this.content = res.content;
      this.publishedTime = res.publishedTime;
      // this.video.options.poster = res.coverImage;
      this.url = res.video;
      this.poster = res.poster;
      this.loading = false;
    });
  },
};
</script>

<style lang="less" scoped>
.article-container {
  padding: 0 16px;
  .article-title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }
  .article-date {
    text-align: left;
    font-size: 14px;
    color: #969799;
  }
  .article-content {
    font-size: 14px;
    color: #646566;
  }
}
</style>
<style lang="less">
.article-content {
  .wscnph {
    width: 100%;
  }
}
</style>
