import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./permission";
import "./utils/filter";

Vue.config.productionTip = false;

router.afterEach(() => {
  window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
