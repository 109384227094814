<template>
  <div>
    <van-list v-model="loading" :finished="finished" @load="onLoad">
      <van-cell v-for="item in list" :key="item.id">
        <van-card
          :title="item.title"
          :desc="item.description"
          :thumb="item.coverImage"
          @click="() => onClick(item)"
        >
          <template #tags v-if="item.tags.length > 0">
            <van-row gutter="6">
              <van-col v-for="tag in item.tags" :key="tag.id">
                <van-tag type="primary" color="#e2b2b8">{{ tag.name }}</van-tag>
              </van-col>
            </van-row>
          </template>
          <template #price>
            <span style="color: #ccc">{{ item.publishedTime | day }}</span>
          </template>
        </van-card>
      </van-cell>
      <template #finished>
        <p style="margin-top: 0px; padding-bottom: 2px; background-color: #fff">
          没有更多了
        </p>
      </template>
    </van-list>
  </div>
</template>

<script>
import { List, Cell, PullRefresh, Card, Tag, Row, Col, Skeleton } from "vant";
import * as articleService from "@/api/article";
import * as pathToRegexp from "path-to-regexp";

export default {
  name: "ArticleList",
  components: {
    [List.name]: List,
    [Cell.name]: Cell,
    [PullRefresh.name]: PullRefresh,
    [Card.name]: Card,
    [Tag.name]: Tag,
    [Row.name]: Row,
    [Col.name]: Col,
    [Skeleton.name]: Skeleton,
  },
  watch: {
    $route() {
      this.getCategory();
      this.getList(0);
    },
  },
  mounted() {
    this.getCategory();
    this.getList(0);
  },
  data() {
    return {
      category: null,
      list: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    getCategory() {
      this.category = pathToRegexp("/categories/:category/articles").exec(
        this.$route.path
      )[1];
    },
    getList(skipCount) {
      articleService
        .getPagedList({
          categoryId: this.category,
          published: true,
          skipCount,
          maxResultCount: 10,
        })
        .then((res) => {
          if (skipCount < 1) {
            this.list = [...res.items];
          } else {
            this.list = [...this.list, ...res.items];
          }
          this.finished = this.list.length >= res.totalCount;
          this.loading = false;
        });
    },
    onLoad() {
      this.getList(this.list.length);

      // // 异步更新数据
      // // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push({
      //       title:
      //         "减重与代谢外科|《LSG术后反弹再手术方式的选择》--- 周某某 教授 1",
      //       description: "地点：苏州市工业园区",
      //       coverImage:
      //         "http://192.168.3.22:44336/images/39fedd68bb245506b13a6cab4d09b52f/article.jpeg",
      //       isOuterLink: false,
      //       outerLink: null,
      //       published: true,
      //       publishedTime: "2021-09-08T02:48:15.362253",
      //       limitedToRegistered: true,
      //       readingQuantity: 0,
      //       tags: [
      //         { name: "英途学院", id: "39fed57b-2bca-a4b4-21ac-12e6e48a1502" },
      //         { name: "推荐好课", id: "39fed57b-2bf5-cd16-f1cb-6531714590ab" },
      //       ],
      //       lastModificationTime: "2021-09-09T23:45:49.905669",
      //       lastModifierId: "39fe934d-9e05-8afc-0ffb-d8e70d8e57e5",
      //       creationTime: "2021-09-08T10:48:15.615851",
      //       creatorId: "39fe934d-9e05-8afc-0ffb-d8e70d8e57e5",
      //       id: this.list.length + 1,
      //     });
      //   }

      //   // 加载状态结束
      //   this.loading = false;

      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 200);
    },
    onClick(item) {
      if (item.limitedToRegistered && !this.$store.getters.currentUser) {
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.fullPath },
        });
        return;
      }

      if (item.isOuterLink) {
        window.location.href = item.outerLink;
      } else {
        this.$router.push({
          name: "article-show",
          params: {
            id: item.id,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.van-cell {
  padding: 3px 0px;
  margin-bottom: 6px;
}
.van-cell::after {
  border-bottom-width: 0;
}
.van-list {
  background-color: #ebedf0;
}
.van-card {
  background-color: #fff;
}
</style>
