import router from "./router";
import store from "./store";
import NProgress from "nprogress";
import "@/components/NProgress/nprogress.less";
import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { Notify } from "vant";

NProgress.configure({ showSpinner: false });

// const allowList = [];
const loginRoutePath = "/user/login";
const defaultRoutePath = "/";

router.beforeEach((to, from, next) => {
  NProgress.start();

  if (store.getters.addRouters.length < 1) {
    store.dispatch("GenerateRoutes").then(() => {
      // console.log("store.getters.addRouters", store.getters.addRouters);
      router.addRoutes(store.getters.addRouters);

      // 请求带有 redirect 重定向时，登录自动重定向到该地址
      const redirect = decodeURIComponent(from.query.redirect || to.path);
      if (to.path === redirect) {
        // set the replace: true so the navigation will not leave a history record
        next({ ...to, replace: true });
      } else {
        // 跳转到目的路由
        next({ path: redirect });
      }
    });
  } else {
    if (storage.get(ACCESS_TOKEN)) {
      if (to.path === loginRoutePath) {
        next({ path: defaultRoutePath });
        NProgress.done();
      } else {
        if (store.getters.currentUser === null) {
          store
            .dispatch("GetInfo")
            .then(() => {
              next();
            })
            .catch(() => {
              Notify({
                background: "#db6b81",
                message: "请求用户信息失败",
              });
              store.dispatch("Logout").then(() => {
                next({
                  path: loginRoutePath,
                  query: { redirect: to.fullPath },
                });
              });
            });
        } else {
          next();
        }
      }
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
