<template>
  <route-view></route-view>
</template>

<script>
import { RouteView } from "@/layouts";

export default {
  name: "Article",
  components: {
    RouteView,
  },
};
</script>
