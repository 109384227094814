import Vue from "vue";
import Vuex from "vuex";
import storage from "store";
import { generatorDynamicRouter } from "@/router/generator-routers";
import { login, getInfo } from "@/api/account";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { getSettings } from "@/api/settings";

Vue.use(Vuex);

const constantRouterMap = []; //TODO

export default new Vuex.Store({
  state: {
    routers: constantRouterMap,
    addRouters: [],
    token: "",
    currentUser: null,
    roles: [],
    settings: {},
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_CURRENT_USER: (state, currentUser) => {
      state.currentUser = currentUser;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_SETTINGS: (state, settings) => {
      state.settings = settings;
    },
  },
  actions: {
    GenerateRoutes({ commit }) {
      return new Promise((resolve) => {
        generatorDynamicRouter().then((routers) => {
          getSettings().then((settings) => {
            commit("SET_SETTINGS", settings);
            commit("SET_ROUTERS", routers);
            resolve();
          });
        });
      });
    },
    Login({ commit }, params) {
      return new Promise((resolve, reject) => {
        login(params)
          .then((res) => {
            const token = `${res.token_type} ${res.access_token}`;
            // const token =
            //   "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjgwNzk4QUZFN0JCRENDNEEwRjBGNzI2QkMxMDg4QUExIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2MzEyMDE5OTMsImV4cCI6MTY2MjczNzk5MywiaXNzIjoiaHR0cDovLzE5Mi4xNjguMy4yMjo0NDMzNiIsImF1ZCI6IkFjYWRlbXkiLCJjbGllbnRfaWQiOiJBY2FkZW15X0FwcCIsInN1YiI6IjM5ZmVkYzA3LTgzMTUtNGM0OS05MDAyLTE5NTI2ZTE3ZGI1MSIsImF1dGhfdGltZSI6MTYzMTIwMTk5MywiaWRwIjoibG9jYWwiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9naXZlbm5hbWUiOiLlpKfnjosiLCJyb2xlIjoibm9ybWFsIiwicGhvbmVfbnVtYmVyIjoiMTMyNTUxOTU4NTMiLCJwaG9uZV9udW1iZXJfdmVyaWZpZWQiOiJUcnVlIiwiZW1haWwiOiIxMzI1NTE5NTg1MyIsImVtYWlsX3ZlcmlmaWVkIjoiRmFsc2UiLCJuYW1lIjoiMTMyNTUxOTU4NTMiLCJpYXQiOjE2MzEyMDE5OTMsInNjb3BlIjpbIkFjYWRlbXkiLCJhZGRyZXNzIiwiZW1haWwiLCJvcGVuaWQiLCJwaG9uZSIsInByb2ZpbGUiLCJyb2xlIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbImNhcHRjaGFfY3JlZGVudGlhbHMiXX0.I0wQM89lAV0Bq7ac9N1wDvEzbS31KdDW_TVFk2BjxNOO73ZZxQBZOISfILGfgJoIWmWMuGpAyinpU_T6uSqEp6tfFUlty5XSTy3krng96Bt-XiHKi7nblXjZBfOS44GCUTGVgjiNffkt_bGud2DXZaJIbeYN7oGPMieqlYo1Mei4e2XOtz_nmzPpTTL4i-qpJAhN7qWVCou_AhOb1vYGLgz-2yALlDM2BouW7ZIR08X3gXzFC8By5HVcfFAio6JSgeArVK_APEiBzZryzXnahuZ-VKN7jEoOtYyzx_0FaMEplwHNjGIO3CiK0Bx4N-pI-BvPi1RrNnmJ4lBgaPtsIw";
            storage.set(ACCESS_TOKEN, token, res.expires_in);
            commit("SET_TOKEN", token);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            const currentUser = res.currentUser;

            if (!currentUser.isAuthenticated) {
              reject(new Error("登录失效，请重新登录"));
              return;
            }
            const roles = res.currentUser.roles;

            commit("SET_CURRENT_USER", currentUser);
            commit("SET_ROLES", roles);

            resolve(currentUser);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    Logout({ commit }) {
      return new Promise((resolve) => {
        commit("SET_CURRENT_USER", null);
        commit("SET_TOKEN", "");
        commit("SET_ROLES", []);
        storage.remove(ACCESS_TOKEN);
        resolve();
      });
    },
  },
  getters: {
    addRouters: (state) => state.addRouters,
    currentUser: (state) => state.currentUser,
    settings: (state) => state.settings,
  },
});
