import { UserLayout } from "@/layouts";
import ArticleShow from "@/views/article/show";

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: "/user",
    component: UserLayout,
    redirect: "/user/login",
    children: [
      {
        path: "/user/login",
        name: "login",
        component: () => import("@/views/user/login"),
      },
      {
        path: "/user/register",
        name: "register",
        component: () => import("@/views/user/register"),
      },
    ],
  },
  {
    path: "/articles/:id",
    name: "article-show",
    component: ArticleShow,
  },
  {
    path: "/404",
    component: () => import("@/views/exception/404"),
  },
];
