<template>
  <div>
    <router-view></router-view>
    <van-tabbar
      safe-area-inset-bottom
      active-color="#db6b81"
      v-model="bottomMenusActive"
    >
      <van-tabbar-item
        v-for="bottomMenu in bottomMenus"
        :key="bottomMenu.id"
        :icon="bottomMenu.meta.icon"
        :to="bottomMenu.path"
        replace
      >
        {{ bottomMenu.meta.title }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";

// var menusData = [
//   {
//     path: "/home",
//     title: "英途视界",
//     icon: "home-o",
//     children: [
//       {
//         path: "/home/a",
//         title: "手术直播",
//       },
//       {
//         path: "/home/b",
//         title: "会议直播",
//       },
//       {
//         path: "/home/c",
//         title: "直播回放",
//       },
//       {
//         path: "/home/d",
//         title: "手术视频",
//         children: [
//           {
//             path: "/home/d/a",
//             title: "英创视界",
//           },
//           {
//             path: "/home/d/b",
//             title: "英途一刻",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     path: "/home1",
//     title: "晴彩英途",
//     icon: "flower-o",
//     children: [
//       {
//         path: "/home1/a",
//         title: "肠路领航",
//       },
//       {
//         path: "/home1/b",
//         title: '"英"领未来',
//       },
//       {
//         path: "/home1/c",
//         title: "IntoCare into Future",
//       },
//       {
//         path: "/home1/d",
//         title: "其他学术活动",
//       },
//     ],
//   },
//   {
//     path: "/home2",
//     title: "英途菁英",
//     icon: "medal-o",
//   },
//   {
//     path: "/home3",
//     title: "外科资讯",
//     icon: "newspaper-o",
//   },
//   {
//     path: "/profile",
//     title: "In 会员",
//     icon: "user-o",
//   },
// ];

export default {
  name: "BasicLayout",
  data() {
    return {
      bottomMenusActive: 0,
    };
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  mounted() {
    this.bottomMenusActive = this.matchMenu(this.bottomMenus);
  },
  watch: {
    $route() {
      this.bottomMenusActive = this.matchMenu(this.bottomMenus);
    },
  },
  computed: {
    bottomMenus() {
      return this.$store.getters.addRouters[0].children || [];
    },
  },
  methods: {
    matchMenu(menus) {
      return menus.findIndex((menu) => {
        return this.$route.matched.find((m) => {
          return m.path == menu.path;
        });
      });
    },
  },
};
</script>
