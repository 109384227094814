<template>
  <div class="profile-container">
    <div class="background-circle"></div>
    <van-cell-group inset>
      <van-cell
        :title="currentUser.isAuthenticated ? currentUser.name : '点击登录'"
        :label="currentUser.isAuthenticated ? '' : '登录发现更多功能'"
        size="large"
        center
        title-class="login-title"
        @click="login"
      >
        <template #icon>
          <van-icon name="user-circle-o" size="3rem" color="#969799"></van-icon>
        </template>
      </van-cell>
      <van-cell :title="welcome"></van-cell>
    </van-cell-group>
    <div style="height: 32px"></div>
    <van-cell-group inset>
      <van-cell title="关于我们"></van-cell>
      <van-cell>
        <div v-html="settings.about || ''" class="info-content" />
      </van-cell>
    </van-cell-group>
    <div style="height: 16px"></div>
    <van-cell-group inset>
      <van-cell title="联系方式"></van-cell>
      <van-cell>
        <div v-html="settings.contact || ''" class="info-content" />
      </van-cell>
    </van-cell-group>
    <div style="height: 80px"></div>
  </div>
</template>

<script>
import { Tabs, Tab, Field, CellGroup, Cell, Button, Icon } from "vant";
import { timeFix } from "@/utils/util";

export default {
  name: "Profile",
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Icon.name]: Icon,
  },
  data() {
    return {
      welcome: "",
      currentUser: {},
      settings: {},
    };
  },
  mounted() {
    this.welcome = `欢迎，${timeFix()}!`;
    this.currentUser = this.$store.getters.currentUser || {};
    this.settings = this.$store.getters.settings || {};
  },
  methods: {
    login() {
      if (this.currentUser.isAuthenticated) {
        return;
      }

      this.$router.push({
        name: "login",
        query: { redirect: this.$route.fullPath },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.profile-container {
  position: absolute;
  padding: 48px 0 16px 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: #ebedf0;
  overflow-x: hidden;

  // .van-cell {
  //   padding-top: 18px;
  //   padding-bottom: 18px;
  // }
}

.background-circle {
  background: linear-gradient(to right, #e2b2b8, #db6b81);
  width: 1000px;
  height: 1000px;
  border-radius: 500px;
  position: absolute;
  margin-left: -500px;
  top: -850px;
  left: 50%;
}
.van-icon {
  margin-right: 12px;
}
.login-title {
  font-weight: bold;
}
</style>
<style lang="less">
.info-content {
  .wscnph {
    width: 100%;
  }
}
</style>
