import * as categoryService from "@/api/category";
import { BasicLayout } from "@/layouts";

const constantRouterComponents = {
  BasicLayout,
  Article: () => import("@/views/article/index"),
  ArticleList: () => import("@/views/article/list"),
  Profile: () => import("@/views/user/profile"),
};

const rootRouter = {
  key: "",
  path: "",
  component: "BasicLayout",
  redirect: "",
  children: [],
};

const notFoundRouter = {
  path: "*",
  redirect: "/404",
};

const constantRouter = [
  {
    id: 1,
    parentId: null,
    name: "profile",
    path: "/user/profile",
    component: "Profile",
    meta: {
      title: "In 会员",
      icon: "user-o",
    },
  },
];

export const generatorDynamicRouter = () => {
  return new Promise((resolve, reject) => {
    categoryService
      .getAllList()
      .then((res) => {
        var categories = res.items
          // .filter((item) => {
          //   return !item.parentId;
          // })
          .map((item) => {
            return {
              name: item.name,
              parentId: item.parentId,
              id: item.id,
              key: item.id,
              meta: {
                title: item.displayName,
                icon: item.icon,
                show: true,
              },
              component: "ArticleList",
              path: `/categories/${item.id}/articles`,
            };
          });
        rootRouter.redirect = categories.filter((item) => {
          return !item.parentId;
        })[0].path;
        categories = categories.concat(constantRouter);
        const menuNav = [];
        const childrenNav = [];
        listToTree(categories, childrenNav, null);
        rootRouter.children = childrenNav;
        menuNav.push(rootRouter);

        const routers = generator(menuNav);
        routers.push(notFoundRouter);
        resolve(routers);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  return routerMap.map((item) => {
    const { title, show, icon } = item.meta || {};
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path || `${(parent && parent.path) || ""}/${item.key}`,
      // 路由名称，建议唯一
      name: item.name || item.key || "",
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component:
        constantRouterComponents[item.component || item.key] ||
        (() => import(`@/views/${item.component}`)),

      // 重定向
      redirect: item.redirect || undefined,

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        key: item.key || undefined,
      },

      hidden: false,
    };
    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true;
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith("http")) {
      currentRouter.path = currentRouter.path.replace("//", "/");
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect);
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter);
    }
    return currentRouter;
  });
};

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach((item) => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: [],
      };
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id);
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children;
      } else {
        child.redirect = child.children[0].path;
      }
      // 加入到树中
      tree.push(child);
    }
  });
};
