import request from "@/utils/request";

export function getPagedList(params) {
  params = { ...params, sorting: "PublishedTime desc" };
  return request({
    url: "/api/academy/articles",
    method: "get",
    params,
  });
}

export function getArticle(id) {
  return request({
    url: `/api/academy/articles/${id}`,
    method: "get",
  });
}
