<template>
  <div>
    <van-sticky>
      <top-menu v-if="topMenus.length > 0" :menus="topMenus"></top-menu>
    </van-sticky>
    <template>
      <article-list></article-list>
      <div style="height: 70px"></div>
    </template>
  </div>
</template>

<script>
import TopMenu from "@/components/TopMenu";
import ArticleList from "@/components/ArticleList";
import * as pathToRegexp from "path-to-regexp";
import { Tag, Row, Col, Cell, Sticky } from "vant";

export default {
  name: "ArticleListPage",
  components: {
    [ArticleList.name]: ArticleList,
    [TopMenu.name]: TopMenu,
    [Tag.name]: Tag,
    [Row.name]: Row,
    [Col.name]: Col,
    [Cell.name]: Cell,
    [Sticky.name]: Sticky,
  },
  data() {
    return {
      category: null,
    };
  },
  mounted() {
    this.getCategory();
  },
  watch: {
    $route() {
      this.getCategory();
    },
  },
  computed: {
    topMenus() {
      var parent = this.$store.getters.addRouters[0].children.find((r) => {
        return r.meta.key == this.category;
      });
      return (parent && parent.children) || [];
    },
  },
  methods: {
    getCategory() {
      this.category = pathToRegexp("/categories/:category/articles").exec(
        this.$route.matched[1].path
      )[1];
    },
  },
};
</script>

<style lang="less" scoped>
.van-tag {
  padding: 4px 7px;
}
</style>
